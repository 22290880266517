import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FiChevronLeft, FiX } from 'react-icons/fi';
import { useFormik } from 'formik';
import DataGridGenerated from './DataGridGenerated';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import xlsx from 'json-as-xlsx';
import { navigate } from '@reach/router';
import ReportFilter from './ReportFilter';
import { t } from 'i18next';
import BarChart from './BarChart';
import moment from 'moment';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';

export default function LiveLessonsAccessReport() {
  const client = useClient();
  const [report, setReport] = useState({});
  const [loading, setLoading] = useState(false);
  const [dateOrder, setDateOrder] = useState('descendent');
  const [liveClassroomOrder, setLiveClassroomOrder] = useState('descendent');
  const [studentOrder, setStudentOrder] = useState('descendent');
  const [emailOrder, setEmailOrder] = useState('descendent');
  const [profileOrder, setProfileOrder] = useState('descendent');
  const [institutionOrder, setInstitutionOrder] = useState('descendent');
  const [inepOrder, setInepOrder] = useState('descendent');
  const [greOrder, setGreOrder] = useState('descendent');
  const [param, setParam] = useState('name');
  const [emptyReport, setEmptyReport] = useState(false);
  const [hasData, setHasData] = useState(true);

  //data dos filtros
  const [currentFilters, setCurrentFilters] = useState([]);
  const [courses, setCourses] = useState([]);
  const [courseIds, setCourseIds] = useState([]);

  //filtros
  const [selectedCourses, setSelectedCourses] = useState([]);

  const filters = useFormik({
    initialValues: {
      'initial-date': moment().subtract(1, 'months').format('YYYY-MM-DD'),
      'final-date': moment().format('YYYY-MM-DD')
    }
  });

  const fetchCourses = async (url = '/courses/simple') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar regionais');
    } else {
      setCourses(data.map(item => ({ value: item.id, label: item.title })));
    }
  };

  const getReport = async () => {
    setLoading(true);
    setEmptyReport(false);

    let url = `/reports/live_classroom_reports?[initial_date]=${filters.values?.['initial-date']}&[final_date]=${filters.values?.['final-date']}`;

    if (courseIds.length > 0) {
      url = `/reports/live_classroom_reports?[initial_date]=${filters.values?.['initial-date']}&[final_date]=${filters.values?.['final-date']}&course_ids=${courseIds}`;
    }

    const { data, error } = await client.fetch(url);
    if (data?.logs?.length === 0) {
      setEmptyReport(true);
    }

    if (error) {
      toast.error('Falha ao carregar relatório');
    } else {
      setReport(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCourses();
    getReport();
  }, []);

  const data = useMemo(() => {
    //atualiza os seletores de acordo com os filtros aplicados
    return [
      {
        placeholder: 'Digite o nome do curso',
        name: 'Curso',
        options: courses
      }
    ];
  }, [courses]);

  const orderByField = useCallback(
    (field, order) => (a, b) => {
      const valueA = a[field]?.toUpperCase?.() || '';
      const valueB = b[field]?.toUpperCase?.() || '';

      if (order === 'descendent') {
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
      } else {
        if (valueA > valueB) return -1;
        if (valueA < valueB) return 1;
        return 0;
      }
    },
    []
  );

  const xlsxStructure = [
    {
      sheet: 'Aulas ao vivo',
      columns: [
        { label: 'Data', value: 'Data' },
        { label: 'Aula', value: 'Aula' },
        { label: 'Aluno', value: 'Aluno' },
        { label: 'Email', value: 'Email' },
        { label: 'Perfil', value: 'Perfil' },
        { label: 'Escola', value: 'Escola' },
        { label: 'INEP', value: 'INEP' },
        { label: 'GRE', value: 'GRE' }
      ],
      content: report?.logs?.map(item => ({
        Data: moment(item?.['access-date']).format('DD/MM/YYYY'),
        Aula: item?.['live-classroom-title'],
        Aluno: item.name,
        Email: item.email,
        Perfil: item.profile,
        Escola: item.institution,
        INEP: item.inep,
        GRE: item.gre
      }))
    }
  ];

  const handleDownloadCSV = () => {
    try {
      xlsx(xlsxStructure);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setCourseIds(currentFilters.map(item => item.value));
  }, [currentFilters]);

  // useEffect(() => {
  //   // setReport(null);
  //   getReport();
  // }, [courseIds]);

  useEffect(() => {
    setCurrentFilters([...selectedCourses]);
  }, [selectedCourses]);

  const handleRemoveFilterItem = item => {
    //remover itens do filtro
    switch (item.type) {
      case 'curso':
        setSelectedCourses(prevState => prevState.filter(r => r.value !== item.value));
        break;
    }
  };

  const today = moment().format('YYYY/MM/DD');

  useEffect(() => {
    if (filters.values['final-date'] !== '' && filters.values['initial-date'] !== '') {
      setHasData(true);
      getReport();
    } else {
      setHasData(false);
      setEmptyReport(false);
    }
  }, [filters.values, courseIds]);

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <a
            className="card__icon-wrapper"
            onClick={() => navigate(-1)}
            style={{ cursor: 'pointer' }}
          >
            <FiChevronLeft className="card__icon" />
          </a>
          Relatório de Acessos às Aulas ao Vivo
        </h1>
        <p className="report__description">Objetivo: Acompanhar quais escolas e GREs estão assistindo as aulas ao vivo.</p>
      </header>
      <div className="report__body">
        <div className="report-widget__header">
          <h3 className="report-widget__title">
            <ReportFilter
              setSelectedCourses={setSelectedCourses}
              selectedcourses={selectedCourses}
              currentFilters={currentFilters}
              setCurrentFilters={setCurrentFilters}
              data={data}
            />
          </h3>

          <div className="report-widget__filter">
            <div className="report-form__datepicker">
              <input
                className="report-form__control"
                id="initial-date"
                name="initial-date"
                type="date"
                value={moment(filters.values['initial-date']).format('YYYY-MM-DD')}
                onChange={e => filters.setFieldValue('initial-date', e.target.value)}
                title="Data de início"
                aria-label="Data de início"
              />
              <input
                className="report-form__control"
                id="final-date"
                name="final-date"
                type="date"
                value={moment(filters.values['final-date']).format('YYYY-MM-DD')}
                onChange={e => filters.setFieldValue('final-date', e.target.value)}
                min={moment(filters.values['initial-date']).format('YYYY-MM-DD')}
                title="Data de fim"
                aria-label="Data de fim"
              />
            </div>
          </div>
        </div>
        <div>
          {currentFilters?.length > 0 && (
            <div onClick={e => e.stopPropagation()}>
              {currentFilters?.map(badge => (
                <span
                  className="report-filter__badge u-ml-2"
                  key={badge?.value}
                >
                  {badge?.label}{' '}
                  <FiX
                    className="report-filter__badge-icon"
                    onClick={() => handleRemoveFilterItem(badge)}
                  />
                </span>
              ))}
            </div>
          )}
        </div>
        <div className="report-widget">
          <div className="report-widget__body">
            {!hasData && !loading && !emptyReport && (
              <EmptyState
                type="add"
                title="Selecione um período (data inicial e final)"
                text=" "
                bgless
              />
            )}
            {emptyReport && (
              <EmptyState
                type="content"
                title="Nenhum dado encontrado"
                text="Tente buscar por outro curso ou por nova data"
                bgless
              />
            )}

            {loading && <Loader />}

            {hasData && !emptyReport && !loading && (
              <div className="round-dg-wrapper">
                <div className="report-kpi-card-wrapper">
                  <div className="report-kpi-card u-mb-5 ">
                    <h3 className="report-kpi-card__title">Quantidade total de acessos únicos</h3>
                    <p className="report-kpi-card__value">{report['total-unique-accesses']}</p>
                  </div>
                </div>

                {/* <div className="report-widget__graphics">
              <div className="card card--p-md">
                <BarChart data={report?.['institution-unique-accesses']} />
              </div>
              <div className="card card--p-md">
                <BarChart data={report?.['gre-unique-accesses']} />
              </div>
            </div> */}
                <div className="report-widget__header u-mb-4 u-mt-4">
                  <div></div>
                  <button
                    className="btn btn--outline"
                    onClick={handleDownloadCSV}
                  >
                    Download relatório
                  </button>
                </div>

                {report?.logs?.length > 0 && (
                  <DataGridGenerated
                    setParam={setParam}
                    dateOrder={dateOrder}
                    setDateOrder={setDateOrder}
                    liveClassroomOrder={liveClassroomOrder}
                    setLiveClassroomOrder={setLiveClassroomOrder}
                    studentOrder={studentOrder}
                    setStudentOrder={setStudentOrder}
                    emailOrder={emailOrder}
                    setEmailOrder={setEmailOrder}
                    profileOrder={profileOrder}
                    setProfileOrder={setProfileOrder}
                    institutionOrder={institutionOrder}
                    setInstitutionOrder={setInstitutionOrder}
                    inepOrder={inepOrder}
                    setInepOrder={setInepOrder}
                    greOrder={greOrder}
                    setGreOrder={setGreOrder}
                    data={report.logs}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
