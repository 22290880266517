import moment from 'moment';
import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

export default function DataGrid({ report }) {
  return (
    <table
      className="round-dg round-dg--light"
      style={{ minWidth: 800 }}
    >
      <thead className="round-dg__header">
        <tr className="round-dg__row">
          <th
            style={{ width: 110 }}
            className="round-dg__cell-header round-dg__cell-header--sm"
          >
            Data
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {dateOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setDateOrder('ascendent');
                    setParam('date');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('date');
                    setDateOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th className="round-dg__cell-header round-dg__cell-header--sm">
            Aluno
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {studentOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setStudentOrder('ascendent');
                    setParam('student');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('student');
                    setStudentOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th className="round-dg__cell-header round-dg__cell-header--sm">
            E-mail
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {emailOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setEmailOrder('ascendent');
                    setParam('email');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('email');
                    setEmailOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th
            style={{ width: 90 }}
            className="round-dg__cell-header round-dg__cell-header--sm"
          >
            Perfil
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {profileOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setProfileOrder('ascendent');
                    setParam('profile');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('profile');
                    setProfileOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th className="round-dg__cell-header round-dg__cell-header--sm">
            Escola
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {institutionOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setInstitutionOrder('ascendent');
                    setParam('institution');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('institution');
                    setInstitutionOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th
            style={{ width: 80 }}
            className="round-dg__cell-header round-dg__cell-header--sm"
          >
            INEP
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {inepOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setInepOrder('ascendent');
                    setParam('inep');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('inep');
                    setInepOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th className="round-dg__cell-header round-dg__cell-header--sm">
            GRE
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {greOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setGreOrder('ascendent');
                    setParam('gre');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('gre');
                    setGreOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
          <th className="round-dg__cell-header round-dg__cell-header--sm">
            Aula
            {/* <span
              className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer"
              style={{ marginLeft: '4px' }}
            >
              {classOrder === 'descendent' ? (
                <FiChevronDown
                  onClick={() => {
                    setClassOrder('ascendent');
                    setParam('name');
                  }}
                  className="card__icon"
                />
              ) : (
                <FiChevronUp
                  onClick={() => {
                    setParam('name');
                    setClassOrder('descendent');
                  }}
                  className="card__icon"
                />
              )}
            </span> */}
          </th>
        </tr>
      </thead>

      <tbody className="round-dg__body">
        {report?.logs?.map((item, key) => (
          <tr
            className="round-dg__row"
            key={key}
          >
            <td className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{new Date(item['access-date']).toLocaleDateString('pt-br')}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item.name}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item.email}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item.profile}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item.institution}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item.inep}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item.gre}</td>
            <td className="round-dg__cell round-dg__cell--sm">{item['learning-system-item-body']}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
