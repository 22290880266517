import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import moment from 'moment';

export default function DataGridGenerated({
  data,
  dateOrder,
  setDateOrder,
  liveClassroomOrder,
  setLiveClassroomOrder,
  studentOrder,
  setStudentOrder,
  emailOrder,
  setEmailOrder,
  profileOrder,
  setProfileOrder,
  institutionOrder,
  setInstitutionOrder,
  inepOrder,
  setInepOrder,
  greOrder,
  setGreOrder,
  setParam
}) {
  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 800 }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th
              className="round-dg__cell-header round-dg__cell-header--sm "
              style={{ width: '120px' }}
            >
              Data
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {dateOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setDateOrder('ascendent');
                      setParam('date');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('date');
                      setDateOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              Aula
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {liveClassroomOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setLiveClassroomOrder('ascendent');
                      setParam('live-classroom');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('live-classroom');
                      setLiveClassroomOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              Aluno
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {studentOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setStudentOrder('ascendent');
                      setParam('student');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('student');
                      setStudentOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              E-mail
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {emailOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setEmailOrder('ascendent');
                      setParam('email');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('email');
                      setEmailOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              Perfil
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {profileOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setProfileOrder('ascendent');
                      setParam('profile');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('profile');
                      setProfileOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              Escola
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {institutionOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setInstitutionOrder('ascendent');
                      setParam('institution');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('institution');
                      setInstitutionOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: '100px' }}
            >
              INEP
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {inepOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setInepOrder('ascendent');
                      setParam('inep');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('inep');
                      setInepOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              GRE
              {/* <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer u-ml-2">
                {greOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setGreOrder('ascendent');
                      setParam('gre');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setParam('gre');
                      setGreOrder('descendent');
                    }}
                    className="card__icon"
                  />
                )}
              </span> */}
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {data?.map((item, key) => (
            <tr
              className="round-dg__row"
              key={key}
            >
              <td className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">{moment(item?.['access-date']).format('DD/MM/YYYY')}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.['live-classroom-title']}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.name}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.email}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.profile}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.institution}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.inep}</td>
              <td className="round-dg__cell round-dg__cell--sm">{item?.gre}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
