import React, { useEffect, useState } from 'react';
import BreadCrumbs from 'app/components/BreadCrumbs';
import NavSection from './NavSection';
import SectionArticle from './SectionArticle';
import { FiVideo } from 'react-icons/fi';
import VideoContentModal from './VideoContentModal';
import { useClient } from 'jsonapi-react';
import { useParams } from '@reach/router';
import { navigate } from '@reach/router';
import Loader from 'app/components/loader';

export default function Block() {
  const [finished, setFinished] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [video, setVideo] = useState(null);
  const [scormData, setScormData] = useState(null);
  const [sections, setSections] = useState([]);
  const [activeSectionIndex, setActiveSectionIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const { blockId } = useParams();

  const client = useClient();

  const fetchData = () => {
    setLoading(true);
    Promise.all([getStudyPlanVideoById(blockId), getScormData(blockId)]).then(([video, scorm]) => {
      const sortedScorm = sortScormDataByScormId(scorm);

      const questionsScorm = sortedScorm.filter(item => item.scorm.scorm_type === 'questions');
      const otherScorm = sortedScorm.filter(item => item.scorm.scorm_type !== 'questions');

      const sections = [...otherScorm, ...questionsScorm].map(section => ({
        id: section.id,
        title: section.scorm.scorm_type === 'introduction' ? 'Introdução' : section.scorm.scorm_type === 'questions' ? 'Avaliação de conhecimento' : section.scorm.title,
        done: section.done,
        isActive: false
      }));

      setSections(sections);
      setVideo(video);
      setScormData([...otherScorm, ...questionsScorm]);
      getIndexOfActiveSession([...otherScorm, ...questionsScorm]);
    });
    setLoading(false);
  };

  const getStudyPlanVideoById = async id => {
    const response = await client.fetch(`study_plan_videos/${id}`);
    return response.data;
  };

  const getScormData = async id => {
    const response = await client.fetch(`study_plan_videos/${id}/scorm_contents`);
    return response.data;
  };

  const getIndexOfActiveSession = scorm => {
    const index = scorm.findIndex(s => !s.done);
    if (index === -1) setActiveSectionIndex(0);
    else setActiveSectionIndex(index);

    const newScorm = scorm.map((s, i) => (i === activeSectionIndex ? { ...s, isActive: true } : { ...s, isActive: false }));
    setScormData(newScorm);
  };

  const sortScormDataByScormId = scorm => {
    return scorm.sort((a, b) => a.scorm.id - b.scorm.id);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const breadCrumbs = taxonomy => ({
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: '/guia-de-estudos',
        name: 'Guia de estudos',
        isActive: false
      },
      {
        route: `/guia-de-estudos/bloco-de-estudo/${blockId}`,
        name: taxonomy,
        isActive: true
      }
    ]
  });

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs data={breadCrumbs(video?.taxonomy?.name)} />
        {loading && <Loader />}
        {video && !loading && (
          <>
            <div className="study-block">
              <div className="study-block__header">
                <div className="study-block__header-inner">
                  <h2 className="study-block__title">{video?.taxonomy?.name}</h2>

                  <div
                    className="card__badges card__badges--one-line u-mb-0"
                    style={{ lineHeight: 1, alignItems: 'center' }}
                  >
                    {video.video?.taxonomies.slice(0, 3).map((taxonomy, index) => (
                      <span
                        className="badge badge--tiny badge--primary"
                        title={taxonomy.name}
                        key={index}
                      >
                        {taxonomy.name}
                      </span>
                    ))}
                    {video.video?.taxonomies.length > 3 && (
                      <span
                        className="badge badge--tiny badge--more"
                        title={video.video?.taxonomies
                          .slice(3)
                          .map(t => t.name)
                          .join(', ')}
                      >
                        +{video.video?.taxonomies.length - 3}
                      </span>
                    )}
                  </div>
                </div>

                <button
                  className="study-block__btn"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  <FiVideo /> Conteúdo em vídeo
                </button>
              </div>

              <div className="study-block__content">
                <NavSection
                  data={sections}
                  finished={finished}
                  setActiveSectionIndex={setActiveSectionIndex}
                  activeSectionIndex={activeSectionIndex}
                />

                {scormData && (
                  <SectionArticle
                    scorm={scormData[activeSectionIndex]}
                    study_plan_video_id={blockId}
                    introduction_image={video.video['introduction-image']}
                    last={activeSectionIndex === scormData.length - 1}
                  />
                )}
              </div>
            </div>

            <VideoContentModal
              show={showModal}
              onClose={() => setShowModal(false)}
              video={video.video}
            />
          </>
        )}
      </main>
    </>
  );
}
