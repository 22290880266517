import React, { useState } from 'react';
import BreadCrumbs from 'app/components/BreadCrumbs';
import filter from './img/filter.svg';
import tia from './img/tia.svg';
import trophy from './img/trophy.svg';
import line from './img/line.svg';
import DiagnosticTest from './DiagnosticTest';
import Lesson from './Lesson';
import Quiz from './Quiz';
import StudyGuide from './StudyGuide';
import Monitoring from './Monitoring';
import LegendModal from './LegendModal';

export default function StudyGuidePanorama(props) {
  const { uri } = props;

  const [showModal, setShowModal] = useState(false);

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: '/guia-de-estudos',
        name: 'Guia de estudos',
        isActive: false
      },
      {
        route: uri,
        name: 'Como o Guia funciona',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="panorama">
        <div className="panorama__header">
          <h1 className="panorama__title">Como seus interesses influenciam o seu Guia de Estudos?</h1>
          <button
            type="button"
            className="btn btn--outline"
            onClick={() => setShowModal(true)}
          >
            Legenda
          </button>

          <LegendModal
            show={showModal}
            onClose={() => setShowModal(false)}
          />
        </div>

        <div className="panorama__body">
          <div className="panorama__bg">
            <img
              className="panorama__line"
              src={line}
              alt="linha"
            />
          </div>

          <section className="panorama-section">
            <div className="panorama-section__header">
              <img
                className="panorama-section__img"
                src={filter}
                alt="filtro"
              />
              <h2 className="panorama-section__title">Todas as suas interações nos são constantemente coletadas pela nossa Tecnologia de Inteligência Artificial</h2>
            </div>

            <div className="panorama-section__body">
              <p className="panorama-section__description">Todas as aulas que você iniciou e quizzes que respondeu são levados em consideração para personalizar o seu guia.</p>
              <div className="panorama-section__card-list">
                <Lesson />
                <Quiz />
              </div>
            </div>
          </section>

          <section className="panorama-section">
            <div className="panorama-section__header">
              <img
                className="panorama-section__img"
                src={tia}
                alt="T.I.A"
              />
              <h2 className="panorama-section__title">A T.I.A, nossa IA, cuidadosamente analisa e organiza suas preferências para criar o Guia de Estudos ideal para você, então...</h2>
            </div>

            <div className="panorama-section__body">
              <p className="panorama-section__description">As interações no seu própria Guia de Estudos e as monitorias que você participa também são essenciais para a T.I.A entender como você aprende</p>
              <div className="panorama-section__card-list">
                <StudyGuide />
                {/* <Monitoring /> */}
              </div>
            </div>
          </section>

          <section className="panorama-section">
            <div className="panorama-section__header">
              <img
                className="panorama-section__img"
                src={trophy}
                alt="troféu"
              />
              <h2 className="panorama-section__title">... traçamos uma trilha com conteúdos personalizados e atualizados semanalmente para te auxiliar.</h2>
            </div>

            <div className="panorama-section__body">
              <p className="panorama-section__description">Nas provas diagnósticas, você mostra suas forças e seus pontos de melhoria para que a T.I.A possa ajustar seu Guia às suas necessidades. </p>
              <div className="panorama-section__card-list">
                <DiagnosticTest />
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
}
