import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FiChevronDown, FiChevronLeft, FiChevronUp } from 'react-icons/fi';
import { useFormik } from 'formik';
import { useCourse } from 'app/hooks/useCourse';
import ReportForm from 'app/components/ReportForm';
import EmptyState from 'app/components/EmptyState';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import xlsx from 'json-as-xlsx';
import { navigate } from '@reach/router';
import ReportFilter from 'app/components/ReportFilter';
import moment from 'moment';
import BarChart from './BarChart';
import DataGrid from './DataGrid';
import RecordClassesReportFilter from './RecordClassesReportFilter';
import Loader from 'app/components/loader';

export default function RecordClassesReport() {
  const client = useClient();
  const [report, setReport] = useState({});
  const [studentOrder, setStudentOrder] = useState('descendent');
  const [dateOrder, setDateOrder] = useState('descendent');
  const [emailOrder, setEmailOrder] = useState('descendent');
  const [institutionOrder, setInstitutionOrder] = useState('descendent');
  const [greOrder, setGreOrder] = useState('descendent');
  const [classOrder, setClassOrder] = useState('descendent');
  const [profileOrder, setProfileOrder] = useState('descendent');
  const [inepOrder, setInepOrder] = useState('descendent');
  const [param, setParam] = useState('');
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [modules, setModules] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchModules = async (url = '/learning_systems') => {
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Falha ao carregar módulos');
    } else {
      setModules(data.map(item => ({ value: item.id, label: item.title })));
    }
  };

  const xlsxStructure = [
    {
      sheet: 'Acessos às aulas gravadas',
      columns: [
        { label: 'Data', value: 'Data' },
        { label: 'Aluno', value: 'Aluno' },
        { label: 'Email', value: 'Email' },
        { label: 'Perfil', value: 'Perfil' },
        { label: 'Escola', value: 'Escola' },
        { label: 'INEP', value: 'INEP' },
        { label: 'GRE', value: 'GRE' },
        { label: 'Aula', value: 'Aula' }
      ],
      content: report?.logs?.map(item => ({
        Data: new Date(item['access-date']).toLocaleDateString('pt-br'),
        Aluno: item.name,
        Email: item.email,
        Perfil: item.profile,
        Escola: item.institution,
        INEP: item.inep,
        GRE: item.gre,
        Aula: item['learning-system-item-body']
      }))
    }
  ];

  const handleDownloadCSV = () => {
    try {
      xlsx(xlsxStructure);
    } catch (e) {
      console.log(e);
    }
  };

  const previousMonth = new Date();

  previousMonth.setMonth(new Date().getMonth() - 1);

  const filters = useFormik({
    initialValues: {
      'start-date': previousMonth.toISOString().substring(0, 10),
      'end-date': new Date().toISOString().substring(0, 10)
    }
  });

  const { courses, getSimpleCourses } = useCourse();

  useEffect(() => {
    if (courses.length === 0) {
      getSimpleCourses();
    }
    fetchModules();
  }, []);

  const data = useMemo(() => {
    return [
      {
        placeholder: 'Selecione o curso',
        name: 'Curso',
        options: courses.map(item => ({ value: item.id, label: item.title }))
      },
      {
        placeholder: 'Selecione o módulo',
        name: 'Módulo',
        options: modules
      },
      {
        placeholder: 'Selecione a aula',
        name: 'Aula',
        options: classes
      }
    ];
  }, [courses, modules]);

  useEffect(() => {
    setCurrentFilters([...selectedModules, ...selectedCourses]);
  }, [selectedModules, selectedCourses]);

  const handleRemoveFilterItem = item => {
    //remover itens do filtro
    switch (item.type) {
      case 'course':
        setSelectedCourses(prevState => prevState.filter(c => c.value !== item.value));
        break;
      case 'module':
        setSelectedModules(prevState => prevState.filter(m => m.value !== item.value));
        break;
      case 'class':
        setSelectedClasses(prevState => prevState.filter(i => i.value !== item.value));
        break;
    }
  };

  const fetchLessons = async () => {
    const { data, error } = await client.fetch('/learning_system_items?simple=true');
    if (error) {
      toast.error('Erro ao buscar aulas');
    } else {
      setClasses(data);
    }
  };

  useEffect(() => {
    fetchLessons();
  }, []);

  const fetchReport = async () => {
    setLoading(true);
    let url = '/reports/learning_system_item_reports?';
    let params = [];
    if (filters.values['start-date'] && filters.values['end-date']) {
      params.push(`initial_date=${filters.values['start-date']}&final_date=${filters.values['end-date']}`);
    }
    if (selectedCourses.length > 0) {
      params.push(`course_ids=${selectedCourses.map(item => item.value)}`);
    }
    if (selectedModules.length > 0) {
      params.push(`learning_system_ids=${selectedModules.map(item => item.value)}`);
    }
    if (selectedClasses.length > 0) {
      params.push(`learning_system_item_ids=${selectedClasses.map(item => item.value)}`);
    }

    const { data, error } = await client.fetch(url + params.join('&'));
    if (error) {
      toast.error('Erro ao buscar relatório');
    } else {
      setReport(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchReport();
  }, [filters.values, currentFilters]);

  return (
    <main className="report">
      <header className="report__header">
        <h1 className="report__title">
          <a
            className="card__icon-wrapper"
            onClick={() => navigate(-1)}
            style={{ cursor: 'pointer' }}
          >
            <FiChevronLeft className="card__icon" />
          </a>
          Relatório de Acessos às Aulas Gravadas
        </h1>
        <p className="report__description">Objetivo: Acompanhar quais alunos, perfis, escolas ou GRE estão assistindo as aulas ao gravadas.</p>
      </header>

      <div className="report__body">
        <div className="report__filter report__filter--space report__filter--multi-select">
          <RecordClassesReportFilter
            setSelectedCourses={setSelectedCourses}
            selectedCourses={selectedCourses}
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
            setSelectedModules={setSelectedModules}
            setSelectedClasses={setSelectedClasses}
            data={data}
            handleRemoveFilterItem={handleRemoveFilterItem}
          />
          <div
            className="report-widget__filter"
            style={{ display: 'block' }}
          >
            <div className="report-form__datepicker">
              <input
                className="report-form__control"
                id="start-date"
                name="start-date"
                type="date"
                value={filters.values['start-date']}
                onChange={e => filters.setFieldValue('start-date', e.target.value)}
                title="Data de início"
                aria-label="Data de início"
              />
              <input
                className="report-form__control"
                id="end-date"
                name="end-date"
                type="date"
                value={filters.values['end-date']}
                onChange={e => filters.setFieldValue('end-date', e.target.value)}
                min={moment(filters.values['start-date']).format('YYYY-MM-DD')}
                title="Data de fim"
                aria-label="Data de fim"
              />
            </div>
          </div>
        </div>
        <div className="report-widget">
          <div className="report-widget__body">
            <div className="round-dg-wrapper">
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div
                    className="report-widget__header"
                    style={{ justifyContent: 'flex-end' }}
                  >
                    <div className="report-widget__filter">
                      <button
                        className="btn btn--outline"
                        type="button"
                        disabled={false}
                        onClick={handleDownloadCSV}
                      >
                        Download do relatório
                      </button>
                    </div>
                  </div>
                  <div
                    className="report-kpi-card-wrapper "
                    style={{ padding: '32px 0' }}
                  >
                    <div className="report-kpi-card">
                      <span className="report-kpi-card__title">Quantidade total de acessos únicos</span>
                      <span
                        className="report-kpi__value"
                        style={{ color: '#5D6A86', marginTop: '24px' }}
                      >
                        <b>{report?.['total-unique-accesses']}</b>
                      </span>
                    </div>
                    <div className="report-kpi-card">
                      <span className="report-kpi-card__title">Taxa de visualização</span>
                      <span
                        className="report-kpi__value"
                        style={{ color: '#5D6A86', marginTop: '24px' }}
                      >
                        <b>{report?.['average-accesses']}%</b>
                      </span>
                      <p style={{ fontSize: '12px' }}>Porcentagem de alunos que acessaram pelo menos uma aula</p>
                    </div>
                    <div className="report-kpi-card">
                      <span className="report-kpi-card__title">Tópico mais assistido</span>
                      <span>
                        <b>{report?.['taxonomy-most-accessed']?.name}</b>
                      </span>
                      <span
                        className="report-kpi__value"
                        style={{ color: '#5D6A86', marginTop: '24px' }}
                      >
                        <b>{report?.['taxonomy-most-accessed']?.count}</b>
                      </span>
                    </div>
                    <div className="report-kpi-card">
                      <span className="report-kpi-card__title">Tópico menos assistido</span>
                      <span>
                        <b>{report?.['taxonomy-least-accessed']?.name}</b>
                      </span>
                      <span
                        className="report-kpi__value"
                        style={{ color: '#5D6A86', marginTop: '24px' }}
                      >
                        <b>{report?.['taxonomy-least-accessed']?.count}</b>
                      </span>
                    </div>
                  </div>
                </>
              )}

              {/* <div
                className="report-kpi-card-wrapper "
                style={{ padding: '32px 0' }}
              >
                <div className="report-kpi-card">
                  <BarChart
                    client={client}
                    title="Escolas mais presentes"
                  />
                </div>
                <div className="report-kpi-card">
                  <BarChart
                    client={client}
                    title="GREs mais presentes"
                  />
                </div>
              </div> */}

              {!loading && report.logs?.length > 0 ? (
                <DataGrid
                  report={report}
                  setParam={setParam}
                  studentOrder={studentOrder}
                  dateOrder={dateOrder}
                  setDateOrder={setDateOrder}
                  setStudentOrder={setStudentOrder}
                  emailOrder={emailOrder}
                  setEmailOrder={setEmailOrder}
                  profileOrder={profileOrder}
                  setProfileOrder={setProfileOrder}
                  inepOrder={inepOrder}
                  setInepOrder={setInepOrder}
                  greOrder={greOrder}
                  setGreOrder={setGreOrder}
                  institutionOrder={institutionOrder}
                  setInstitutionOrder={setInstitutionOrder}
                  classOrder={classOrder}
                  setClassOrder={setClassOrder}
                  data={[{ user: 'user', respostas: 2, erros: 2, acertos: 3, topico: 'teste', caderno: 'testee' }]}
                />
              ) : !loading ? (
                <EmptyState
                  type="content"
                  title="Nenhum dado encontrado"
                  text="Tente buscar por outro curso"
                  bgless
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
