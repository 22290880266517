import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import brain1 from './img/brain-1.svg';
import brain2 from './img/brain-2.svg';
import brain3 from './img/brain-3.svg';
import brain4 from './img/brain-4.svg';
import brain5 from './img/brain-5.svg';
import Brain from '../LegendModal/Brain';

export default function ProgressLvlTag(props) {
  const { percentage = 0, id } = props;

  const item =
    percentage <= 20
      ? {
          src: brain1,
          title: 'Começando a entender',
          subtitle: 'Você está dando os primeiros passos no aprendizado deste conteúdo. As informações ainda são novas, e é normal que leve um tempo para assimilar tudo. Continue estudando para aprofundar o entendimento.',
          modifier: 'danger'
        }
      : percentage > 20 && percentage <= 40
      ? {
          src: brain2,
          title: 'Ganhando clareza',
          subtitle: 'Você já está começando a se familiarizar com os conceitos. O conhecimento está se firmando e as ideias estão ficando mais claras. Mantenha o ritmo para solidificar o aprendizado.',
          modifier: 'third'
        }
      : percentage > 40 && percentage <= 60
      ? {
          src: brain3,
          title: 'Conteúdo assimilado',
          subtitle: 'Você já absorveu boa parte do conteúdo e está se tornando mais confiante. Os conceitos principais estão sendo compreendidos, e você está no caminho certo para dominar o assunto.',
          modifier: 'warning'
        }
      : percentage > 60 && percentage <= 80
      ? {
          src: brain4,
          title: 'Boa retenção',
          subtitle: 'Você está retendo a maior parte do conteúdo com sucesso. Suas habilidades e conhecimento estão bem desenvolvidos, e você está quase pronto para aplicar o que aprendeu de forma prática.',
          modifier: 'success-light'
        }
      : {
          src: brain5,
          title: 'Ótima retenção',
          subtitle: 'Parabéns! Você atingiu um nível excelente de retenção de conhecimento. Agora, você domina o conteúdo e está preparado para utilizá-lo com confiança em diferentes contextos.',
          modifier: 'success'
        };

  return (
    <>
      <div
        className={`progress-lvl-tag progress-lvl-tag--${item?.modifier}`}
        data-tooltip-id={id + 1}
        data-tooltip-place="top-end"
      >
        <p
          className="progress-lvl-tag__text"
          title={item?.title}
        >
          {item?.title}
        </p>
        <div className="progress-lvl-tag__img-wrapper">
          <img
            className="progress-lvl-tag__img"
            src={item?.src}
            alt="cérebro"
          />
        </div>
      </div>

      <ReactTooltip
        id={id + 1}
        className="progress-lvl__tooltip"
        noArrow
      >
        <Brain percentage={percentage} />

        <div className="progress-lvl__content">
          <h4 className="progress-lvl__title">{item?.title}</h4>
          <p className="progress-lvl__subtitle">{item?.subtitle}</p>
        </div>
      </ReactTooltip>
    </>
  );
}
