import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MedalSuccess } from 'app/images/icons/medal-success.svg';
import { useClient } from 'jsonapi-react'; // Importa o hook para fazer requisições

export default function SolveQuestions(props) {
  const { data } = props;

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answer, setAnswer] = useState(null);
  const [done, setDone] = useState(false);
  const { t } = useTranslation();
  const client = useClient(); // Instancia o client para fazer as requisições
  const [rightAnswers, setRightAnswers] = useState(0);

  const question = data?.[currentQuestion];

  async function handleAnswer() {
    if (answer !== null) {
      // Envia a resposta para a API
      const response = await client.mutate(
        [`scorm_questions/${question.id}/scorm_answers`],
        {
          alternative_id: question.alternatives[answer].id
        },
        { method: 'POST' }
      );
      if (response?.data?.['is-correct']) {
        setRightAnswers(prev => prev + 1);
      }
    }

    if (currentQuestion + 1 < data?.length) {
      setCurrentQuestion(currentQuestion + 1);
    }

    setAnswer(null);

    if (currentQuestion + 1 === data?.length) {
      setDone(true);
    }
  }

  if (!done)
    return (
      <div className="solve-questions">
        <div className="solve-questions__header">
          <h3 className="solve-questions__title">
            Questão {currentQuestion + 1}/{data?.length}
          </h3>
        </div>

        <div className="solve-questions__body">
          <p>{question?.motivator_text}</p>
          <p className="solve-questions__text">
            <strong>{question?.description}</strong>
          </p>

          {question?.alternatives?.map((option, index) => (
            <div
              className="form__check"
              key={index}
            >
              <input
                className="form__check-input"
                type="radio"
                id={`option-${index}`}
                name="option"
                checked={index === answer}
                onChange={() => setAnswer(index)}
              />
              <label htmlFor={`option-${index}`}>{option.name}</label>
            </div>
          ))}
        </div>

        <div className="solve-questions__footer">
          <button
            type="button"
            className="btn btn--wide btn--outline"
            onClick={handleAnswer}
          >
            {currentQuestion + 1 === data?.length ? 'Responder e finalizar' : 'Responder e continuar'}
          </button>
        </div>
      </div>
    );

  return (
    <div className="solve-questions">
      <div className="flashcard-success">
        <div className="flashcard-success__inner">
          <MedalSuccess className="flashcard-success__medal" />

          <h3 className="flashcard-success__title">
            {t('flashCard.successTitle')}
            <br />
            Você acertou {rightAnswers}/{data.length}
          </h3>

          <p className="flashcard-success__description">{t('flashCard.successDescription')}</p>

          <button className="btn btn--wide btn--review">Próximo bloco de estudos</button>
        </div>
      </div>
    </div>
  );
}
