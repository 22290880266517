import BreadCrumbs from 'app/components/BreadCrumbs';
import Loader from 'app/components/loader';
import { useSession } from 'app/hooks/useSession';
import React, { useEffect, useRef, useState } from 'react';
import KpiWidget from './KpiWidget';
import FastAccessWidget from './FastAccessWidget';
import { FiEdit3, FiMap, FiMessageSquare, FiMonitor, FiUsers } from 'react-icons/fi';
import { BsFileEarmarkCheck } from 'react-icons/bs';
import IconPerformance from 'app/components/Icons/IconPerformance';
import { BiJoystickAlt } from 'react-icons/bi';
import { useNotification } from 'app/hooks/useNotification';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import Notification from 'app/components/DropdownMessages/Notification';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

const DashboardTeacher = () => {
  const [loading, setLoading] = useState(false);
  const { session } = useSession();

  const { t } = useTranslation();
  const { notificationsComment, setNotificationsComment, notificationCommentCount, setNotificationCommentCount, getNotificationsComment } = useNotification();
  const client = useClient();
  const menuRef = useRef();
  const [pendingCorrections, setPendingCorrections] = useState();

  const getPendingCorrections = async () => {
    const { data, error } = await client.fetch('/dashboards/cards');

    if (error) {
      toast.error('Erro ao buscar correções pendentes');
    } else {
      setPendingCorrections(data);
    }
  };

  useEffect(() => {
    getPendingCorrections();
  }, []);

  const unreadNotifications = notificationsComment?.filter(item => item.unread === true);

  const breadCrumbs = {
    title: 'Início',
    nav: [
      {
        route: '/',
        name: `Olá, ${session?.user?.name}`,
        isActive: true
      }
    ]
  };

  const shortcuts = [
    {
      title: 'Monitoria',
      link: '/auditorio',
      icon: (
        <FiMessageSquare
          size={20}
          style={{ maxWidth: 90 }}
        />
      )
    },
    {
      title: 'Redação',
      icon: (
        <FiEdit3
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/redacao'
    },
    {
      title: 'Atividades',
      icon: (
        <BsFileEarmarkCheck
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/atividade-complementar'
    },
    {
      title: 'Cursos',
      icon: (
        <FiMonitor
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/curso/admin'
    },
    {
      title: 'Plano de aula',
      icon: (
        <FiMap
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/plano-de-aulas/inicio'
    },
    {
      title: 'Minhas turmas',
      icon: (
        <FiUsers
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/turmas'
    },
    {
      title: 'Desafio da semana',
      icon: (
        <BiJoystickAlt
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/desafio-da-semana/desafios'
    },
    {
      title: 'Relatórios',
      icon: (
        <IconPerformance
          size={20}
          style={{ maxWidth: 90 }}
        />
      ),
      link: '/metricas/institucional'
    }
  ];

  return loading ? (
    <Loader />
  ) : (
    <>
      <BreadCrumbs data={breadCrumbs} />
      <div className="kpi-widget-dashboard u-mb-5">
        <h3 className="frequent-shortcut-widget-wrapper__title u-mb-3">Para corrigir</h3>
        <div className="kpi-widget-wrapper">
          <KpiWidget
            title="Redações para revisar"
            count={pendingCorrections?.['surveys_count']}
            link="Acessar redações"
            route="/redacao"
          />

          <KpiWidget
            title="Projetos (versões para avaliar)"
            count={pendingCorrections?.['project_versions_count']}
            link="Acessar projetos"
            route="/projetos/grupos"
          />

          <KpiWidget
            title="Atividades para avaliar"
            count={pendingCorrections?.['activities_count']}
            link="Acessar atividades"
            route="/atividade-complementar"
          />
        </div>
      </div>

      <h3 className="frequent-shortcut-widget-wrapper__title u-mb-3">Acessos rápidos</h3>
      <div className="fast-access-wrapper">
        {shortcuts.map(item => (
          <FastAccessWidget
            title={item.title}
            icon={item.icon}
            link={item.link}
          />
        ))}
      </div>

      <h3 className="frequent-shortcut-widget-wrapper__title u-mt-3 u-mb-3">Mensagens</h3>

      {unreadNotifications.length === 0 && (
        <EmptyState
          type="notification"
          title={t('emptyState.notificationTitle')}
        />
      )}
      {unreadNotifications?.map(notification => (
        <div className="notifications__body notifications__body--dashboard">
          <Notification
            notifications={notificationsComment}
            notification={notification}
            setNotificationCount={setNotificationCommentCount}
            setNotifications={setNotificationsComment}
            notificationCount={notificationCommentCount}
            key={notification.id}
          />
        </div>
      ))}
    </>
  );
};

export default DashboardTeacher;
