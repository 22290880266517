import React, { useState, useEffect } from 'react';
import { FiStar } from 'react-icons/fi';
import { useSurvey } from 'app/hooks/useSurvey';
import Modal from 'app/components/Modal';
import SurveyAnswer from 'app/components/Survey/SurveyAnswer';
import MyProgressWidget from './MyProgressWidget';
import { t } from 'i18next';
import WallSlider from './WallSlider';
import { useClient, useQuery } from 'jsonapi-react';
import Loader from 'app/components/loader';
import moment from 'moment';
import WarningWall from './WarningWall';
import BreadCrumbs from 'app/components/BreadCrumbs';
import { useSession } from 'app/hooks/useSession';
import Disclaimer from './disclaimer';
import GuideCardDashboard from './GuideCardDashboard';
import ShortcutCard from './ShortcutCard';
import roleta from '../../images/Roleta.png';
import monitor from '../../images/Monitor.png';
import { Link } from '@reach/router';
import CourseCard from './CourseCard';
import toast from 'react-hot-toast';

export default function DashboardStudent(props) {
  const client = useClient();
  const { getStudentSurvey, currentStudentSurvey } = useSurvey();
  const { session } = useSession();
  const [alerts, setAlerts] = useState([]);
  const [lastStudyPlan, setLastStudyPlan] = useState();
  const [banners, setBanners] = useState();
  const [loading, setLoading] = useState();
  const [showSurvey, setShowSurvey] = useState();
  const [lastChallenge, setLastChallenge] = useState();
  const [liveClassroom, setLiveClassroom] = useState();
  const [lastCourseWatched, setLastCourseWatched] = useState();

  const initialDate = moment().startOf('month').format('YYYY-MM-DD');
  const endDate = moment().endOf('month').format('YYYY-MM-DD');

  const fetchData = () => {
    const fetchBanners = client.fetch(`/bulletin_boards?filter[date_between][initial]=${initialDate}&filter[date_between][final]=${endDate}`);
    const fetchAlerts = client.fetch(`/dashboards/alerts`);
    const fetchStudyPlan = client.fetch(`/study_plan_videos/current`);
    const fetchChallenge = client.fetch(`/challenges/latest`);
    const fetchLive = client.fetch(`/live_classrooms/next`);
    const fetchCourse = client.fetch(`/courses/latest`);
    setLoading(true);

    Promise.allSettled([fetchBanners, fetchAlerts, fetchStudyPlan, fetchChallenge, fetchLive, fetchCourse]).then(([bannersResponse, alertsResponse, studyPlanResponse, challengeResponse, liveResponse, courseResponse]) => {
      if (bannersResponse.value.error) {
        toast.error('Erro ao buscar banners');
      } else {
        setBanners(bannersResponse.value.data);
      }
      if (alertsResponse.value.error) {
        toast.error('Erro ao buscar alertas');
      } else {
        setAlerts(alertsResponse.value.data.data);
      }
      if (studyPlanResponse.value.error) {
        toast.error('Erro ao buscar guia de estudo');
      } else {
        setLastStudyPlan(studyPlanResponse.value.data);
      }
      if (challengeResponse.value.error) {
        toast.error('Erro ao buscar desafio');
      } else {
        setLastChallenge(challengeResponse.value.data);
      }
      if (liveResponse.value.error) {
        toast.error('Erro ao buscar aula ao vivo');
      } else {
        setLiveClassroom(liveResponse.value.data);
      }
      if (courseResponse.value.error) {
        toast.error('Erro ao buscar último curso acessado');
      } else {
        setLastCourseWatched(courseResponse.value.data);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onCloseSurvey = () => {
    setShowSurvey(false);
    getStudentSurvey();
  };

  const breadCrumbs = {
    title: 'Início',
    nav: [
      {
        route: '/',
        name: `Olá, ${session?.user?.name}`,
        isActive: true
      }
    ]
  };

  const dataCourse = {
    id: lastCourseWatched?.['course_id'],
    title: lastCourseWatched?.['course_title'],
    'finished-learning-system-items': lastCourseWatched?.['finished_items_count'],
    'learning-system-item-count': lastCourseWatched?.['total_items_count'],
    progress: lastCourseWatched?.['course_percent']
  };

  return (
    <>
      <BreadCrumbs data={breadCrumbs} />

      {loading ? (
        <Loader />
      ) : banners?.length > 1 ? (
        <WallSlider data={banners} />
      ) : (
        <WarningWall
          warning={banners?.[0]}
          key={banners?.[0]?.id}
        />
      )}

      <Disclaimer alerts={alerts} />

      <GuideCardDashboard
        studyPlan={lastStudyPlan?.video}
        id={lastStudyPlan?.id}
      />

      <div style={{ display: 'flex', width: '100%', gap: 10 }}>
        <ShortcutCard
          source={roleta}
          type="Desafio da semana"
          title={lastChallenge?.title}
          link="/desafio-da-semana/desafios"
          text={lastChallenge?.taxonomy?.name}
          linkText="Responder desafio"
        />
        <ShortcutCard
          source={monitor}
          type="Aula ao vivo"
          title={liveClassroom?.title}
          link={liveClassroom?.url}
          text={`${moment(liveClassroom?.['initial-date']).format('DD/MM/YYYY')} às ${moment(liveClassroom?.['end-date']).format('DD/MM/YYYY')}`}
          linkText="Acesse aula ao vivo"
        />
      </div>

      {currentStudentSurvey && (
        <div className="info-banner u-mb-4">
          <div className="info-banner__inner">
            <span className="info-banner__icon">
              <FiStar />
            </span>
            <h3 className="info-banner__title">{t('nps.newQuiz')}</h3>
            <p className="info-banner__description">Queremos saber a sua opinião</p>
          </div>

          <button
            onClick={() => setShowSurvey(true)}
            className="btn btn--outline"
          >
            Responder
          </button>
        </div>
      )}

      <div className="my-progress-widget__header">
        <h3 className="my-progress-widget__title">Último curso acessado</h3>
        <Link
          className="btn btn--link"
          to="/curso"
        >
          Acesse cursos
        </Link>
      </div>

      <CourseCard course={dataCourse} />

      {/* <MyProgressWidget /> */}

      <Modal
        show={showSurvey}
        onClose={() => setShowSurvey(false)}
        contentClassName="modal__content--p-md"
      >
        {currentStudentSurvey && (
          <SurveyAnswer
            studentSurvey={currentStudentSurvey}
            onClose={onCloseSurvey}
          />
        )}
      </Modal>
    </>
  );
}
