import React, { useEffect, useState } from 'react';
import { SimpleModal } from 'app/components/Modal';
import HitsByMatterColumnChart from './HitsByMatterColumnChart';
import { useClient } from 'jsonapi-react';
import EmptyState from 'app/components/EmptyState';

export default function DiagnosticTestModal({ show, onClose, question_book_user_id = 0, title = 'Rendimento em prova diagnóstica' }) {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);  
  const [error, setError] = useState(null);      

  const client = useClient();

  const getChartData = async () => {
    try {
      const response = await client.fetch(
        `study_plan_reports/question_books_chart?question_book_user_id=${question_book_user_id}`
      );
      return response.data;
    } catch (err) {
      setError('Erro ao carregar os dados');
      console.error(err);
    }
  };

  useEffect(() => {
    if (show && question_book_user_id) {
      setLoading(true);
      getChartData().then((data) => {
        console.log(data);
        if (data && data.question_books_chart) {
          const chartData =  data.question_books_chart.map((chart) => ({
            value: parseFloat(chart.score).toFixed(2),
            label: chart.name,
          }));

          setChartData(chartData);
        }
        setLoading(false);
      });
    }
  }, [show, question_book_user_id]);

  if (!question_book_user_id) return null;

  return (
    <SimpleModal
      show={show}
      onClose={onClose}
      contentClassName="simple-modal__content--lg"
      hideCloseButton
    >
      <h3 className="modal__simple-title">{title}</h3>

      {loading ? (
        <p>Carregando...</p>
      ) : error ? (
        <p>{error}</p>
      ) : chartData.length > 0 ? (
        <HitsByMatterColumnChart chartData={chartData} />
      ) : (
        <EmptyState
          type="data"
          title="Você ainda não respondeu uma prova diagnóstica"
          text=" "
          bgless
        />
      )}
    </SimpleModal>
  );
}
