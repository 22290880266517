import React, { useState } from 'react';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';
import { attr } from 'highcharts';
import toast from 'react-hot-toast';

export default function MatterCard(props) {
  const { taxonomy, onUpdate } = props;
  const taxonomyChildren = taxonomy?.["taxonomy-children"] || [];
  const firstThreeItems = taxonomyChildren.slice(0, 3);
  const remainingItemsCount = taxonomyChildren.length - firstThreeItems.length;
  const remainingItemsNames = taxonomyChildren.slice(3).map(item => item.name).join(', ');

  const [isBlocked, setIsBlocked] = useState(taxonomy?.['status'] === 'blocked');
  const [isSelected, setIsSelected] = useState(false);

  const client = useClient();

  const updateTaxonomy = async (id, attributes) => {
    try {
      const response = await client.mutate(`user_taxonomies/${id}`, attributes, { method: 'PUT' });
      return response.data;
    } catch (error) {
      console.error('Failed to update taxonomy:', error);
      toast.error('erro ao atualizar taxonomia');
    }
  }

  const handleAdd = () => {
    const updatedTaxonomy = updateTaxonomy(taxonomy.id, { "manual-add": true });
    if (updatedTaxonomy) {
      setIsSelected(true);
      if (onUpdate) onUpdate(updatedTaxonomy);
    }
  };

  const handleRemoveMatter = async () => {
    const updatedTaxonomy = await updateTaxonomy(taxonomy.id, { "manual-add": false });
    if (updatedTaxonomy) {
      setIsSelected(false);
      if (onUpdate) onUpdate(updatedTaxonomy);
    }
  };



  return (
    <tr className="round-dg__row">
      <th
        className="round-dg__cell-header round-dg__cell-header--row"
        style={{ width: 200 }}
      >
        <div className="card__header u-mb-0">
          <div className="card__inner-header">
            <h3 className="card__title">{taxonomy?.["taxonomy-name"]}</h3>
          </div>
        </div>
      </th>
      <td className="round-dg__cell">
        <div
          className="card__badges card__badges--one-line u-mb-0"
          style={{ lineHeight: 1, alignItems: 'center' }}
        >
          {firstThreeItems.map((item) => (
            <span
              key={item.id}
              className="badge badge--tiny"
              title={item.name}
            >
              {item.name}
            </span>
          ))}
          {remainingItemsCount > 0 && (
            <span
              className="badge badge--tiny badge--more"
              title={remainingItemsNames}
            >
              +{remainingItemsCount}
            </span>
          )}
        </div>
      </td>
      <td
        className="round-dg__cell"
        style={{ width: '140px' }}
      >
        <button
          type="button"
          className={`btn btn--full ${isSelected ? 'btn--primary' : 'btn--outline'}`}
          onClick={() => {
            if (isSelected) {
              handleRemoveMatter(); 
            } else {
              handleAdd(); 
            }
          }}
        >
          {isSelected ? t('button.remove') : t('button.add')}
        </button>
      </td>
    </tr>
  );
}
