import React, { useEffect, useState } from 'react';
import { Link, useParams } from '@reach/router';
import { useSession } from '../hooks/useSession';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { i18n } from 'app/components/i18n/i18n';
import { useClient } from 'jsonapi-react';

function Login() {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { session, signIn, isSubmittingSignIn } = useSession();
  const { link } = useParams();
  const client = useClient();
  const sso = process.env.REACT_APP_SSO_API_URL;
  const supportLink = process.env.REACT_APP_SUPPORT_LINK;

  const canCreateAccount = process.env.REACT_APP_ALLOW_CREATE_USER;
  const ssoEtipi = process.env.REACT_APP_ETIPI_SSO_API_URL;

  useEffect(() => {
    if (session && Object.keys(session).length !== 0) {
      const redirectUrl = localStorage.getItem('redirectUrl');
      window.location.pathname = redirectUrl || '/';
    }
  }, [session]);

  useEffect(() => {
    i18n.changeLanguage(process.env.REACT_APP_LOCALE);

    if (sso) {
      window.location.replace(sso);
    }
  }, []);

  const authenticateUser = async e => {
    e.preventDefault();

    if (values.email && values.password) {
      signIn(values);
    } else {
      toast.error(t('toast.errorSetEmailPass'));
    }
  };

  const handleChange = e => {
    const auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues(auxValues);
  };

  const appName = process.env.REACT_APP_NAME;
  const logo = require(`app/images/${appName}/horizontal-logo.svg`);

  let bannerImage;

  switch (appName) {
    case 'canaleducacao':
    case 'maiseducacao':
      bannerImage = require('app/images/canaleducacao/login-bg.png');
      break;
    case 'encceja':
      bannerImage = require('app/images/encceja/login-bg.png');
      break;
    case 'egepi':
      bannerImage = require('app/images/egepi/login-bg.png');
      break;
    case 'pit':
      bannerImage = require('app/images/pit/login-bg.png');
      break;

    default:
      bannerImage = require('app/images/login-bg.jpg');
  }

  const updateUser = async () => {
    const requestParams = {
      link_key: link
    };
    const { error } = await client.mutate(`users/${session.user.id}`, requestParams);
    if (error) {
      toast.error('Erro ao aceitar o convite.');
    } else {
      toast.success('Convite aceito com sucesso!');
      navigate('/');
    }
  };

  useEffect(() => {
    if (session !== null) {
      updateUser();
    }
  }, [session, link]);

  return (
    <div className="new-login">
      <div className="new-login__container">
        <div className="new-login__body">
          <div className="new-login__body-header">
            <div className="new-login__body-logo">
              <img
                alt="Logo Estudologia"
                src={logo}
              />
            </div>
            <div className="new-login__body-title">{t('login.login')}</div>
          </div>
          <div className="new-login__body-form">
            <form
              className="form"
              method="post"
              onSubmit={e => authenticateUser(e)}
            >
              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="email"
                >
                  Email:
                </label>
                <input
                  className="form__control"
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                />
              </div>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="password"
                >
                  {t('login.password')}
                </label>
                <div className="form__row--password">
                  <input
                    title={t('profile.typeYourPass')}
                    className="form__control"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    onChange={handleChange}
                  />
                  <span onClick={() => setShowPassword(!showPassword)}>{showPassword ? <FiEyeOff /> : <FiEye />}</span>
                </div>
              </div>
              <Link
                to="/esqueci-a-senha"
                className="form__row rember-link"
              >
                {t('login.forgotPass')}
              </Link>

              <div className="form__row">
                <input
                  type="submit"
                  disabled={isSubmittingSignIn}
                  value={t('button.continue')}
                />

                {ssoEtipi && <button
                  className="btn btn--primary"
                  type="button"
                  style={{ marginLeft: '10px' }}
                  onClick={() => navigate(`${ssoEtipi}?response_type=code&client_id=projeto-template-integracao&redirect_uri=${process.env.REACT_APP_PRODUCTION_URL}/login-callback/&scope=openid`)}
                > Login via ETIPI </button>}
              </div>
              <div style={{ display: 'flex', gap: '24px' }}>
                {canCreateAccount === 'true' && (
                  <Link
                    to="/criar-uma-conta"
                    className="form__row rember-link"
                  >
                    {t('login.createAccount')}
                  </Link>
                )}
                <a
                  href={supportLink}
                  className="form__row rember-link"
                >
                  Suporte
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="new-login__image">
        <div
          className="new-login__login-image"
          style={{ backgroundImage: `url(${bannerImage})` }}
        >
          {' '}
        </div>
      </div>
    </div>
  );
}

export default Login;
{
  /* <div className="login__footer">
  {['oab', 'retina', 'fic', 'brasilstartups'].includes(appName) ? (
    <>
      Não tem conta? <a href="/register">Registre-se.</a>
    </>
  ) : (
    <>Qualquer dúvida entre em contato: {supportMail}</>
  )}
</div> */
}
