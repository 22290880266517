import React, { useState, useEffect } from 'react';
import mockImg from './img/mock-img.jpg';
import SolveQuestions from '../SolveQuestions';
import FlipCardWrapper from '../FlipCardWrapper';
import ConceptToCharacteristic from '../ConceptToCharacteristic';
import ConceptMatchingGame from '../ConceptMatchingGame';
import { useClient } from 'jsonapi-react';
import MindMap from 'app/components/MindMap';
import { navigate } from '@reach/router';

export default function SectionArticle({ scorm, study_plan_video_id, introduction_image, last }) {
  if (!scorm) return null;

  const [goNext, setGoNext] = useState(false);
  const [scormQuestions, setScormQuestions] = useState([]);
  const [scormAnsweredQuestions, setScormAnsweredQuestions] = useState([]);
  const client = useClient();

  const setScormSectionDone = async scormId => {
    const response = await client.mutate([`study_plan_videos/${study_plan_video_id}/scorm_contents/`, scormId], { done: true }, { method: 'put' });

    return response;
  };

  const getScormQuestions = async study_plan_video_id => {
    let url = `study_plan_videos/${study_plan_video_id}/scorm_questions`;
    if (scorm.scorm.scorm_type === 'questions') {
      url = url.concat(`?type=questions`);
    } else if (scorm.scorm.scorm_type === 'learning_section') {
      url = url.concat(`?type=learning_section`);
    } else {
      url = '';
    }

    if (url !== '') {
      const response = await client.fetch(url);
      return response.data;
    }
  };

  const splitTopics = topics => {
    const conceptsData = topics.map(topic => ({
      id: topic.title.toLowerCase().replace(/\s+/g, ''),
      name: topic.title
    }));

    const meaningsData = topics.map(topic => ({
      id: topic.title.toLowerCase().replace(/\s+/g, ''),
      meaning: topic.content
    }));

    return { conceptsData, meaningsData };
  };

  useEffect(() => {
    getScormQuestions(study_plan_video_id).then(data => {
      setScormQuestions(data);
    });
  }, [scorm]);

  useEffect(() => {
    function handleScroll() {
      const screenHeight = document.documentElement.scrollHeight;
      const endPage = window.scrollY + window.innerHeight;

      if (endPage === screenHeight) setGoNext(false);
      else setGoNext(true);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [goNext]);

  const renderContent = scorm => {
    switch (scorm.scorm_type) {
      case 'questions':
        return <SolveQuestions data={scormQuestions} />;
      case 'educational_content':
        const { conceptsData, meaningsData } = splitTopics(scorm.content.main_topics);
        return (
          <>
            <header className="section-article__header">
              <h1 className="section-article__title">{scorm.content.main_title}</h1>
            </header>

            {scorm.content.introduction.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}

            <ConceptMatchingGame
              conceptsData={conceptsData}
              meaningsData={meaningsData}
            />
          </>
        );
      case 'introduction':
        return (
          <>
            <h2>{scorm.content.title}</h2>
            {introduction_image && (
              <div style={{ display: 'flex', justifyContent: 'center', margin: 30 }}>
                <img
                  src={introduction_image || mockImg}
                  alt="Imagem de introdução"
                  width={'100%'}
                />
              </div>
            )}

            {scorm.content.introduction.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </>
        );
      case 'mindmap':
        return (
          <>
            {scorm.content.introduction.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
            <div style={{ height: 'auto' }}>{scorm.content.main_nodes && <MindMap data={scorm.content.main_nodes} />}</div>
            <br />
            <br />
            {scorm.content.conclusion.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </>
        );
      case 'learning_section':
        return (
          <>
            {scorm.content.introduction.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
            <br />
            <br />
            <ConceptToCharacteristic interactive_challenges={scorm.content.interactive_challenges} />
            <br />
            <br />
            {scorm.content.final_reflection.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </>
        );
      case 'learning_experience':
        return (
          <>
            <h2>{scorm.content.general_title}</h2>
            {scorm.content.introduction.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
            <FlipCardWrapper flip_cards={scorm.content.flip_cards} />
            <br />
            <br />
            {scorm.content.conclusion.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </>
        );
      default:
        return <p>Tipo de conteúdo desconhecido.</p>;
    }
  };

  return (
    <article className="section-article">
      <header className="section-article__header">
        {/* <p className="section-article__hat">Introdução</p> */}
        <h1 className="section-article__title">{scorm.scorm.content.title}</h1>
      </header>

      <div className="section-article__content">{renderContent(scorm.scorm)}</div>

      <footer className="section-article__footer">
        <button
          className="btn btn--review"
          type="button"
          onClick={() => {
            if (last) navigate('/guia-de-estudos');
            setScormSectionDone(scorm.id).then(() => {
              window.location.reload();
            });
          }}
        >
          Próxima seção
        </button>
      </footer>
    </article>
  );
}
