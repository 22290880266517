import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Avatar from '../Avatar';
import tiaAvatar from './img/tia-avatar.png';
import TIAResults from './TIAResults';

function TIA({message}) {
  return (
    <div className="tia">
      <Avatar
        className="avatar--md"
        src={tiaAvatar}
        alt="T.I.A"
        data-tooltip-id="tia"
        data-tooltip-place="top-start"
        data-tooltip-variant="light"
      />

      <ReactTooltip
        id="tia"
        style={{ boxShadow: '0px 4px 10px rgba(68, 43, 140, 0.2)' }}
      >
        <div className="tia__tooltip">
          <h3 className="tia__title">T.I.A</h3>
          <p className="tia__text">{message}</p>
        </div>
      </ReactTooltip>
    </div>
  );
}

export { TIA as default, TIAResults };
