import React, { useEffect, useState } from 'react';
import { PiBinoculars } from 'react-icons/pi';
import { FiSettings } from 'react-icons/fi';
import BreadCrumbs from 'app/components/BreadCrumbs';
import NavWeek from './NavWeek';
import ReviewCard from './ReviewCard';
import GuideTimeline from './GuideTimeline';
import { useSession } from 'app/hooks/useSession';
import { useClient } from 'jsonapi-react';
import Block from './Block';
import { Link } from '@reach/router';
import { use } from 'i18next';
import StudyGuideSettings from './StudyGuideSettings';
import StudyGuidePanorama from './StudyGuidePanorama';
import Loader from 'app/components/loader';

function StudyGuide(props) {
  const { path } = props;
  const { session } = useSession();
  const [studyGuide, setStudyGuide] = useState(null);
  const [loading, setLoading] = useState(false);

  const client = useClient();

  const fetchData = () => {
    setLoading(true);
    getStudyPlan().then(async studyPlan => {
      const guide = await getStudyGuide(studyPlan.id);
      const lastBlock = getStudyBlockWithBiggerId(guide);
      console.log(lastBlock);
      setStudyGuide([lastBlock]);
    });
    setLoading(false);
  };

  const getStudyPlan = async () => {
    const response = await client.fetch('study_plans/latest');
    return response.data;
  };

  const getStudyGuide = async id => {
    const response = await client.fetch(`study_plan_blocks?filter[study_plan_id]=${id}`);
    return response.data;
  };

  const getStudyBlockWithBiggerId = studyGuide => {
    const lastBlock = studyGuide.reduce((prev, current) => (prev.id > current.id ? prev : current));
    return lastBlock;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const { uri } = props;

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: '/guia-de-estudos',
        name: 'Guia de estudos',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="study-guide">
        <div className="study-guide__header">
          <h1 className="study-guide__title">Personalizado para {session.user.name}</h1>
          <div className="study-guide__actions">
            <Link
              to={`${uri}/funcionamento`}
              type="button"
              className="btn btn--outline"
              style={{ width: 60 }}
              aria-label="Funcionamento"
              title="Funcionamento"
            >
              <PiBinoculars className="btn__icon btn__icon--md" />
            </Link>
            <Link
              to={`${uri}/configuracao`}
              className="btn btn--outline"
              style={{ width: 60 }}
              aria-label="Configuração"
              title="Configuração"
            >
              <FiSettings className="btn__icon btn__icon--md" />
            </Link>
          </div>
        </div>

        <div className="study-guide__body">
          <div className="study-guide__content">
            {loading && <Loader />}
            {studyGuide && !loading && <NavWeek studyGuide={studyGuide[0]} />}
            {studyGuide && !loading && <GuideTimeline studyGuide={studyGuide[0]} />}
          </div>

          <aside className="study-guide__sidebar">
            <ReviewCard />
          </aside>
        </div>
      </div>
    </main>
  );
}

export { StudyGuide as default, Block, StudyGuideSettings, StudyGuidePanorama };
