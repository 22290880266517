import React, { useEffect, useState } from 'react';
import { SimpleModal } from 'app/components/Modal';
import IncomeByMatter from 'app/screens/Admin/WeekChallenge/ChallengeResult/ViewParticipantResult/IncomeByMatter';
import closeIcon from '../../../images/icons/close-icon.svg';
import { FiX } from 'react-icons/fi';
import ModalDataGrid from './ModalDataGrid';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

export default function QuestionsReportModal(props) {
  const { showModal, onClose, title, selectedQuestion, startDate, endDate, selectedRegionals, selectedBooklets, selectedInstitutions, selectedTopics, selectedExams } = props;
  const [report, setReport] = useState([]);
  const client = useClient();

  const fetchData = async () => {
    let url = `/reports/question_reports/${selectedQuestion?.id}?`;
    let params = [];

    if (startDate && endDate) {
      params.push(`initial_date=${startDate}&final_date=${endDate}`);
    }
    if (selectedRegionals.length > 0) {
      params.push(`regional_ids=${selectedRegionals.map(item => item.value)}`);
    }
    if (selectedBooklets.length > 0 || selectedExams.length > 0) {
      params.push(`question_book_ids=${[...selectedBooklets?.map(item => item.value), ...selectedExams?.map(item => item.value)]}`);
    }
    if (selectedInstitutions.length > 0) {
      params.push(`institution_ids=${selectedInstitutions?.map(item => item.value)}`);
    }
    if (selectedTopics.length > 0) {
      params.push(`taxonomy_ids=${selectedTopics?.map(item => item.value)}`);
    }

    const { data, error } = await client.fetch(url + params.join('&'));
    if (error) {
      toast.error('Erro ao buscar dados');
    } else {
      setReport(data);
    }
  };

  useEffect(() => {
    if (selectedQuestion) {
      fetchData();
    }

    return setReport([]);
  }, [selectedQuestion]);

  return (
    <SimpleModal
      show={showModal}
      onClose={onClose}
      contentClassName="simple-modal__content--full simple-modal__content--br-md"
      hideCloseButton
    >
      <div className="report-widget__header">
        <header className="widget__header">
          <h1 className="widget__title">{title}</h1>
          <p className="widget__description">Selecione a matéria para mais detalhes do rendimento</p>
        </header>
        <button
          className="card__icon-wrapper card__icon-wrapper--sm u-ml-auto u-mb-2"
          onClick={onClose}
        >
          <FiX className="card__icon" />
        </button>
      </div>
      <ModalDataGrid
        report={report.questions}
        data={[{ user: 'user', respostas: 2, erros: 2, acertos: 3, topico: 'teste', caderno: 'testee' }]}
      />
    </SimpleModal>
  );
}
