import React from 'react';
import { FiAward } from 'react-icons/fi';
import Avatar from '../Avatar';
import tiaAvatar from './img/tia-avatar.png';
import Medal from '../Medal';

export default function TIAResults({message}) {
  console.log('TIAResults', message);
  return (
    <div className="tia-results">
      <Avatar
        className="avatar--md"
        src={tiaAvatar}
        alt="T.I.A"
      />

      <div className="tia-results__content">
        <h3 className="tia-results__title">T.I.A</h3>
        <p className="tia-results__text">{message}</p>

        {/* <div className="tia-results__kpi-wrapper">
          <div className="tia-results__kpi">
            <Medal
              size="tia"
              src="success"
              value={<FiAward />}
            />
            <h4 className="tia-results__kpi-title">Verbos</h4>
            <p className="tia-results__kpi-value">95%</p>
          </div>
          <div className="tia-results__kpi">
            <Medal
              size="tia"
              src="success"
              value={<FiAward />}
            />
            <h4 className="tia-results__kpi-title">Advérbios</h4>
            <p className="tia-results__kpi-value">73%</p>
          </div>
          <div className="tia-results__kpi">
            <Medal
              size="tia"
              src="success"
              value={<FiAward />}
            />
            <h4 className="tia-results__kpi-title">Pronome</h4>
            <p className="tia-results__kpi-value">67%</p>
          </div>
          <div className="tia-results__kpi">
            <Medal
              size="tia"
              src="warning"
              value={<FiAward />}
            />
            <h4 className="tia-results__kpi-title">Adjetivo</h4>
            <p className="tia-results__kpi-value">45%</p>
          </div>
          <div className="tia-results__kpi">
            <Medal
              size="tia"
              src="warning"
              value={<FiAward />}
            />
            <h4 className="tia-results__kpi-title">Advérbios</h4>
            <p className="tia-results__kpi-value">40%</p>
          </div>
          <div className="tia-results__kpi">
            <Medal
              size="tia"
              src="danger"
              value={<FiAward />}
            />
            <h4 className="tia-results__kpi-title">Advérbios</h4>
            <p className="tia-results__kpi-value">20%</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}
